.header {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.confirmation {
  background-color: #3a3a3c;
  border-radius: 5px;
  margin-bottom: 20px;
}

.item {
  margin-bottom: 10px;
	padding: 0px 10px 10px 10px;
	border-bottom: 2px solid #2c2c2e;
}

.item:first-child {
	padding-top: 10px;
}

.item:last-child {
  margin-bottom: 0;
	border-bottom: 0;
}

.label {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  color: #aaa;
}

.value {
  font-size: 15px;
}

.button {
	cursor: pointer;
	font-size: 15px;
  background-color: #ffffff;
  color: #2c2c2e;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center; /* Добавлено для выравнивания текста по центру */
  display: inline-block; /* Добавлено для стилизации ссылки как кнопки */
  text-decoration: none; /* Удаление подчеркивания текста */
}

.button.last {
  background-color: #3a3a3c;
  color: #ffffff;
  margin-top: 5px;
  text-align: center; /* Добавлено для выравнивания текста по центру */
  display: inline-block; /* Добавлено для стилизации ссылки как кнопки */
  text-decoration: none; /* Удаление подчеркивания текста */
}