.label {
  display: block;
  font-size: 12px;
  margin-bottom: 15px;
  color: #aaa;
}

.productName {
  font-size: 24px;
	font-weight: 700;
  margin-bottom: 20px;
}

.section {
  margin-bottom: 20px;
}

.section label {
  display: block;
  margin-bottom: 10px;
}

.section input[type="email"],
.section input[type="button"] {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
}

.section input[type="email"] {
  background-color: #3a3a3c;
  color: #fff;
	font-size: 15px;
}

.paymentMethods {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  column-gap: 5px;
}

.paymentMethodButton {
  flex: 1;
  background-color: #3a3a3c;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
	font-size: 15px;
}

.paymentMethodButton.selected {
	background-color: #ffffff;
  color: #2c2c2e;
}

.price {
  font-size: 24px;
	font-weight: 700;
  margin-bottom: 20px;
}

.button {
	cursor: pointer;
	font-size: 15px;
  background-color: #ffffff;
  color: #2c2c2e;
  border: none;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center; /* Добавлено для выравнивания текста по центру */
  display: inline-block; /* Добавлено для стилизации ссылки как кнопки */
  text-decoration: none; /* Удаление подчеркивания текста */
}
