* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html, body, #root, .App {
	height: 100%;
}

body {
	font-family: Arial, sans-serif;
	background-color: #1c1c1e;
	color: #fff;
}

.wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 5px;
}

.container {
	width: 100%;
	flex: 1;
  background-color: #2c2c2e;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	display: flex;
	flex-direction: column;
}

.content{
	flex: 0 0 auto;
}

.empty_space {
	flex: 1 0 auto;
}

.buttons {
	flex: 0 0 auto;
}


.progress {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 45px;
}

.circle {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #3a3a3c;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	font-size: 14px;
	position: relative;
}

.circle span {
	position: absolute;
	top: 27.5px;
	font-size: 10px;
}

.completed  {
	background-color: #fff;
	color: #2c2c2e;
}

.completed span  {
	color: #fff;
}

.line {
	flex-grow: 0.3;
	height: 2px;
	background-color: #3a3a3c;
	margin: 0 10px;
}

.line.completed {
	background-color: #fff;
}

/* Стили для загрузочного экрана */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.loader-container {
  border: 4px solid #fff;
  border-top: 4px solid #2c2c2e;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}